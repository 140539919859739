import React from "react";
import {Link} from "react-scroll";
import styled from "styled-components";
// Assets
// import LogoImg from "../../assets/svg/logo.svg";

export default function Contact() {

    const getCurrentYear = () => {
        return new Date().getFullYear();
    };

    return (
        <Wrapper>
            <div className="whiteBg">
                <div className="container">
                    <InnerWrapper className="flexSpaceCenter" style={{padding: "30px 0"}}>
                        <AddressSection>
                            <Link className="flexCenter animate pointer" to="home" smooth={true} offset={-20}>
                                <h1 className="font20 extraBold">
                                    CUBE ROBOTICS.
                                </h1>
                            </Link>
                            <ul className="contact-info list-unstyled">
                                <li>
                                    <a href="https://g.page/Cuberobotics?share" target="_blank">
                                        KNUST Commercial

                                        Area,MCJF+QW,

                                        Kumasi</a>
                                </li>
                                <li>
                                    <a href="mailto:hello@cubegh.com" target="_blank">hello@cubegh.com</a>
                                </li>
                                <li>
                                    <a href="tel:+233 24 787 2952" target="_blank">+233 24 787 2952</a>
                                </li>
                                <li>
                                    <a href="tel:+233 50 108 3601" target="_blank">+233 501 083 601</a>
                                </li>
                            </ul>
                        </AddressSection>
                        <AddressSection>
                            <div>
                                <h5 className="font20 extraBold">Quick Links</h5>
                                <ul className="links list-unstyled">
                                    <li><a href="https://susupay.app">SUSU</a></li>
                                    <li><a href="https://financeplus.app">FINANCE PLUS</a></li>
                                    <li><a href="https://flip.delivery">FLIP DELIVERY</a></li>
                                    <li><a href="#">WELECT</a></li>

                                </ul>
                            </div>
                        </AddressSection>
                        <AddressSection>
                            <div>
                                <h5 className="font20 extraBold">Robotics Resources</h5>
                                <ul className="links list-unstyled">
                                    <li><a href="/robotics/competition/">Competition</a></li>
                                    <li><a href="/robotics/rules/">Rules</a></li>
                                    <li><a href="/robotics/register/">Register</a></li>
                                    <li><a href="/robotics/workshop/">Workshop</a></li>
                                </ul>
                            </div>
                        </AddressSection>
                        <AddressSection>
                            <div>
                                <h5 className="font20 extraBold">Who are we?</h5>
                                <p>We are a technology company
                                    focused on solving challenging
                                    problems in Africa with
                                    artificial
                                    intelligence and
                                    robotics.
                                </p>

                                <Link className="animate pointer font13" to="home" smooth={true} offset={-80}>
                                    <p style={{color: '#0B093B', margin: "8px 0"}} className={'extraBold'}>
                                        Back to top
                                    </p>
                                </Link>
                            </div>
                        </AddressSection>
                    </InnerWrapper>
                </div>
            </div>
        </Wrapper>
    );
}

const Wrapper = styled.div`
  width: 100%;
`;
const InnerWrapper = styled.div`
  display: flex;
  width: 100%;
  @media (max-width: 550px) {
    flex-direction: column;
  }
`;
const AddressSection = styled.div`
  margin: 2px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: start;
  @media (max-width: 550px) {
    margin-top: 20px;
  }
`;

const StyleP = styled.p`
  @media (max-width: 550px) {
    margin: 20px 0;
  }
`;