import React       from "react";
import styled      from "styled-components";
import BrowserIcon from "../../assets/svg/Services/BrowserIcon";
import MonitorIcon from "../../assets/svg/Services/MonitorIcon";
import PrinterIcon from "../../assets/svg/Services/PrinterIcon";
// Assets
import RollerIcon  from "../../assets/svg/Services/RollerIcon";

export default function ServiceBox( { icon, title, subtitle } ) {
	let getIcon;

	switch ( icon ) {
		case "robotics":
			getIcon = <RollerIcon />;
			break;
		case "finance":
			getIcon = <MonitorIcon />;
			break;
		case "susu":
			getIcon = <BrowserIcon />;
			break;
		case "welect":
			getIcon = <PrinterIcon />;
			break;
		default:
			getIcon = <RollerIcon />;
			break;
	}


	return (
		<Wrapper className="flex flexColumn" >
			<IconStyle >{ getIcon }</IconStyle >
			<TitleStyle className="font20 extraBold" >{ title }</TitleStyle >
			<SubtitleStyle className="font18" >{ subtitle }</SubtitleStyle >
		</Wrapper >
	);
}

const Wrapper = styled.div`
  padding: .65rem .55rem;
`;
const IconStyle = styled.div`
  @media (max-width: 860px) {
    margin: 0 auto;
  }
`;
const TitleStyle = styled.h2`
  width: 100%;
  text-transform: uppercase;
  max-width: 300px;
  margin: 0 auto;
  padding: 10px 0;
  @media (max-width: 860px) {
    padding: 20px 0;
  }
`;
const SubtitleStyle = styled.p`
  width: 100%;
  max-width: 300px;
  margin: 0 auto;
`;