import React        from "react";
import styled       from "styled-components";
// Assets
import AddImage2    from "../../assets/svg/sv5.svg";
import FullButton   from "../Buttons/FullButton";
// Components
import TeamCarousel from "../Elements/TeamCarousel";

export default function Team() {
	return (
		<Wrapper id="projects" >
			<div className="lightBg" >
				<div className="container" >
					<Advertising className="flexSpaceCenter" >
						<AddLeft >
							<AddLeftInner >
								<ImgWrapper className="flexCenter" >
									<img className="radius8" src={ AddImage2 } alt="add" />
								</ImgWrapper >
							</AddLeftInner >
						</AddLeft >
						<AddRight >
							<h2 className="font40 extraBold" >OUR CULTURE🇬🇭</h2 >
							<p className="font20" >
								The culture of a company is one of the most essential accelerator of growth in a
								company. Our naming conventions are from African native languages and do have great
								respect for the African tradition with massive obsession for attaining mastery to be the
								best in the league.
							</p >

						</AddRight >
					</Advertising >
				</div >
			</div >
		</Wrapper >
	);
}

const Wrapper = styled.section`
  width: 100%;
  margin-bottom: 5px;
`;
const HeaderInfo = styled.div`
  @media (max-width: 860px) {
    text-align: center;
  }
`;
const Advertising = styled.div`
  padding: 100px 0;
  margin: 300px 0;
  position: relative;
  @media (max-width: 1160px) {
    padding: 60px 0 40px 0;
  }
  @media (max-width: 860px) {
    flex-direction: column;
    padding: 0 0 30px 0;
    margin: 80px 0 0px 0;
  }
`;
const ButtonsRow = styled.div`
  @media (max-width: 860px) {
    justify-content: space-between;
  }
`;
const AddLeft = styled.div`
  position: relative;
  width: 50%;

  p {
    max-width: 475px;
  }

  @media (max-width: 860px) {
    width: 80%;
    order: 2;
    text-align: center;
    h2 {
      line-height: 3rem;
      margin: 15px 0;
    }

    p {
      margin: 0 auto;
    }
  }
`;
const AddRight = styled.div`
  width: 50%;
  @media (max-width: 860px) {
    width: 80%;
    order: 2;
  }
`;
const AddLeftInner = styled.div`
  width: 100%;
  position: absolute;
  top: -300px;
  left: 0;
  @media (max-width: 1190px) {
    top: -250px;
  }
  @media (max-width: 920px) {
    top: -200px;
  }
  @media (max-width: 860px) {
    order: 1;
    position: relative;
    top: -60px;
    left: 0;
  }
`;
const ImgWrapper = styled.div`
  width: 100%;
  padding: 0 15%;

  img {
    width: 426px;
    height: 607px;
  }

  @media (max-width: 400px) {
    padding: 0;
  }
`;
