import React      from "react";
import { Helmet } from "react-helmet";
// Screens
import Landing    from "./screens/Landing.jsx";

export default function App() {
	return (
		<>
			<Helmet >
				<link rel="preconnect" href="https://fonts.googleapis.com" />
				<link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin />
				<link href="https://fonts.googleapis.com/css2?family=Khula:wght@400;600;800&display=swap"
					  rel="stylesheet" />
				<link
					rel="stylesheet"
					type="text/css"
					charSet="UTF-8"
					href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css"
				/>
				<link
					rel="stylesheet"
					type="text/css"
					href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css"
				/>
			</Helmet >
			<Landing />
		</>
	);
}
