import React     from "react";
// Sections
import TopNavbar from "../components/Nav/TopNavbar";
import Footer    from "../components/Sections/Footer";
import Header    from "../components/Sections/Header";
import Services  from "../components/Sections/Services";
import Team      from '../components/Sections/Team';

export default function Landing() {
	return (
		<>
			<TopNavbar />
			<Header />
			<Services />
			<Team />
			<Footer />
		</>
	);
}


