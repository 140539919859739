import React     from "react";
import { Link }  from "react-scroll";
import styled    from "styled-components";
// Assets
import CloseIcon from "../../assets/svg/CloseIcon";
import LogoIcon  from "../../assets/svg/logo.svg";

export default function Sidebar( { sidebarOpen, toggleSidebar } ) {
	return (
		<Wrapper className="animate whiteBg" sidebarOpen={ sidebarOpen } >
			<SidebarHeader className="flexSpaceCenter" >
				<div className="flexNullCenter" >
					<img src={ LogoIcon } alt="Logo" />
					<h1 className=" font20" style={ { marginLeft: "15px" } } >
						Cube Robotics.
					</h1 >
				</div >
				<CloseBtn onClick={ () => toggleSidebar ( !sidebarOpen ) } className="animate pointer" >
					<CloseIcon />
				</CloseBtn >
			</SidebarHeader >

			<UlStyle className="flexNullCenter flexColumn" >
				<li className="semiBold font15 pointer" >
					<Link
						onClick={ () => toggleSidebar ( !sidebarOpen ) }
						activeClass="active"
						className=""
						style={ { padding: "10px 15px" } }
						to="home"
						spy={ true }
						smooth={ true }
						offset={ -60 }
					>
						Home
					</Link >
				</li >
				<li className="semiBold font15 pointer" >
					<Link
						onClick={ () => toggleSidebar ( !sidebarOpen ) }
						activeClass="active"
						className=""
						style={ { padding: "10px 15px" } }
						to="services"
						spy={ true }
						smooth={ true }
						offset={ -60 }
					>
						Services
					</Link >
				</li >
				<li className="semiBold font15 pointer" >
					<Link
						onClick={ () => toggleSidebar ( !sidebarOpen ) }
						activeClass="active"
						className=""
						style={ { padding: "10px 15px" } }
						to="projects"
						spy={ true }
						smooth={ true }
						offset={ -60 }
					>
						Team
					</Link >
				</li >
			</UlStyle >
		</Wrapper >
	);
}

const Wrapper = styled.nav`
  width: 400px;
  height: 100vh;
  position: fixed;
  top: 0;
  padding: 0 30px;
  right: ${ ( props ) => (props.sidebarOpen ? "0px" : "-400px") };
  z-index: 9999;
  @media (max-width: 400px) {
    width: 100%;
  }
`;
const SidebarHeader = styled.div`
  padding: 20px 0;
`;
const CloseBtn = styled.button`
  border: 0px;
  outline: none;
  background-color: transparent;
  padding: 10px;
`;
const UlStyle = styled.ul`
  padding: 40px;
  li {
    margin: 20px 0;
  }
`;
