import React, { Component } from 'react';
import AwesomeSlider        from 'react-awesome-slider';
import 'react-awesome-slider/dist/custom-animations/cube-animation.css';
import 'react-awesome-slider/dist/styles.css';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import image2               from "../../assets/img/teams/dennis_appiah_co-founder.jpg";
import image1               from "../../assets/img/teams/isaac_cube_roboticist.jpeg";
import image4               from "../../assets/img/teams/kelvin_osei_poku_founder.jpg";
import image3               from "../../assets/img/teams/samuel-kow-painstil-co-founder.jpg";

export default class TeamCarousel extends Component {
	render() {
		return (

			<AwesomeSlider
				animation="cubeAnimation"
				activityColor="#white"
			>


				<div className="col-xs-12 col-sm-4 col-md-4 col-lg-4" >
					<img src={ image4 } />
					<p style={ { width: "100%" } } className="font20 legend " >Kelvin Osei Poku - Founder</p >
				</div >
				<div className="col-xs-12 col-sm-4 col-md-4 col-lg-4" >
					<img src={ image3 } />
					<p style={ { width: "100%" } } className="font20 legend " >Samuel Kow Painstil - Co-Founder</p >
				</div >
				<div className="col-xs-12 col-sm-4 col-md-4 col-lg-4" >
					<img src={ image2 } />
					<p style={ { width: "100%" } } className="font20 legend " >Dennis Appiah - Co-Founder</p >
				</div >
				<div className="col-xs-12 col-sm-4 col-md-4 col-lg-4" >
					<img src={ image1 } />
					<p style={ { width: "100%" } } className="font20 legend " > Issac Ampah Junior - Product
																				Specialist </p >
				</div >
			</AwesomeSlider >
		);
	}
}

