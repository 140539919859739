import React  from "react";
import styled from "styled-components";

export default function FullButton( { title, action, border } ) {
	return (
		<Wrapper
			className="animate pointer radius8"
			onClick={ action ? () => action () : null }
			border={ border }
		>
			{ title }
		</Wrapper >
	);
}

const Wrapper = styled.button`
  border: 1px solid ${ ( props ) => (props.border ? "#4cb8c4" : "#4cb8c4") };
  background-color: ${ ( props ) => (props.border ? "transparent" : "#4cb8c4") };
  width: 100%;
  padding: 10px 14px;
  outline: none;
  text-transform: capitalize;
  font-weight: bold;
  background-image: linear-gradient(to right, #4cb8c4, #3cd3ad);
  color: ${ ( props ) => (props.border ? "#49523e" : "#fff") };
  transition: all .5s linear;

  :hover {
    background-color: ${ ( props ) => (props.border ? "transparent" : "#49523e") };
    border: 1px solid #4cb8c4;
    color: #000000;
  }
`;

