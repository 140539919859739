import React from "react";
import styled from "styled-components";
// Assets
import AddImage1 from "../../assets/svg/sv1.svg";
import AddImage2 from "../../assets/svg/sv2.svg";
import AddImage3 from "../../assets/svg/sv3.svg";
import AddImage4 from "../../assets/svg/sv4.svg";
// Components
import ServiceBox from "../Elements/ServiceBox";

export default function Services() {
    return (
        <Wrapper id="services">
            <div className="whiteBg" style={{padding: "60px 0"}}>
                <div className="lightBg">
                    <div className="container">
                        <Advertising className="flexSpaceCenter">
                            <AddLeft>

                                <h2 className="font40 extraBold">MISSION💘</h2>
                                <p className="font20">
                                    Unravel talents for future innovations through
                                    technology in Ghana, Africa and beyond.
                                    CUBE ROBOTICS
                                    would push to empower young ones to achieve their
                                    potentials and dreams.
                                </p>
                                <div style={{marginTop: "20px"}}>
                                    <h2 className="font40 extraBold ">VISION🏹</h2>
                                    <p className="font20">
                                        To create the ultimate brilliant experiences in the world of technology.
                                        CUBE ROBOTICS is here to lead innovation in Africa and beyond.
                                    </p>
                                </div>
                            </AddLeft>
                            <AddRight>
                                <AddRightInner>
                                    <div className="flexNullCenter">
                                        <AddImgWrapp1 className="flexCenter">
                                            <img src={AddImage1} alt="office"/>
                                        </AddImgWrapp1>
                                        <AddImgWrapp2>
                                            <img src={AddImage2} alt="office"/>
                                        </AddImgWrapp2>
                                    </div>
                                    <div className="flexNullCenter">
                                        <AddImgWrapp3>
                                            <img src={AddImage3} alt="office"/>
                                        </AddImgWrapp3>
                                        <AddImgWrapp4>
                                            <img src={AddImage4} alt="office"/>
                                        </AddImgWrapp4>
                                    </div>
                                </AddRightInner>
                            </AddRight>
                        </Advertising>
                    </div>
                </div>
                <div className="container">
                    <HeaderInfo>
                        <h1 className="font40 extraBold">Awesome Services</h1>
                        <p className="font20" style={{marginBottom: "20px"}}>
                            There are many variations of our services, tailored to meet your needs.
                            <br/>
                        </p>
                    </HeaderInfo>
                    <ServiceBoxRow>
                        <ServiceBoxWrapper>
                            <ServiceBox
                                icon="robotics"
                                title="Robotics"
                                subtitle="
                Unravel talent and unlock the potential of African engineers. We strongly believe in the skills
                that African engineers possess and when carefully curbed, it would solve most of African problems.
                "
                            />
                        </ServiceBoxWrapper>
                        <ServiceBoxWrapper>
                            <ServiceBox
                                icon="finance"
                                title="Finance plus"
                                subtitle="
              Finance plus is an enterprise solution that enables financial institutions to provide excellent services
              to their customers through a web-based portal and a mobile application. Customers can
              debit or credit their account through the mobile app using third party mobile payment services.
                "
                            />
                        </ServiceBoxWrapper>
                        <ServiceBoxWrapper>
                            <ServiceBox
                                icon="susu"
                                title="Susu"
                                subtitle="
                Susu is a mobile investment platform that enables users to invest their funds with investment companies.
               Users can purchase and manage investment portfolios directly from the susu app.

                "
                            />
                        </ServiceBoxWrapper>
                        <ServiceBoxWrapper>
                            <ServiceBox icon="finance" title="Welect"
                                        subtitle="
                  WeLect seeks to redefine the old ways of elections by resolving long queues during election process.
                  With WeLect, voters can cast their vote through a local internet right from their smart phones."
                            />
                        </ServiceBoxWrapper>
                        <ServiceBoxWrapper>
                            <ServiceBox icon="susu" title="Flip Delivery"
                                        subtitle="
	Flip Delivery is a courier service that mobilizes riders to deliver parcels with ease for users.
	Users can send multiple parcels and track the progress of their item being sent."
                            />
                        </ServiceBoxWrapper>

                    </ServiceBoxRow>
                </div>
            </div>
        </Wrapper>
    );
}

const Wrapper = styled.section`
  width: 100%;
`;
const ServiceBoxRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  @media (max-width: 860px) {
    flex-direction: column;
  }
`;
const ServiceBoxWrapper = styled.div`
  width: 30%;
  padding: .55rem .65rem;
  margin: .55rem .85rem;
  transition: all .5s linear;

  :hover {
    cursor: pointer;
    background-color: #3ECFB0;
    color: #000;
    border-radius: 30px;
  }

  @media (max-width: 860px) {
    width: 95%;
    justify-content: center;
    text-align: center;
    padding: 20px 0;
  }
`;
const HeaderInfo = styled.div`
  @media (max-width: 860px) {
    text-align: center;
  }
`;
const Advertising = styled.div`
  margin: 80px 0;
  padding: 100px 0;
  position: relative;
  @media (max-width: 1160px) {
    padding: 100px 0 40px 0;
  }
  @media (max-width: 860px) {
    flex-direction: column;
    padding: 0 0 30px 0;
    margin: 80px 0 0px 0;
  }
`;
const AddLeft = styled.div`
  width: 50%;

  p {
    max-width: 475px;
  }

  @media (max-width: 860px) {
    width: 80%;
    order: 2;
    text-align: center;
    h2 {
      line-height: 3rem;
      margin: 15px 0;
    }

    p {
      margin: 0 auto;
    }
  }
`;
const AddRight = styled.div`
  width: 50%;
  position: absolute;
  top: -70px;
  right: 0;
  @media (max-width: 860px) {
    width: 80%;
    position: relative;
    order: 1;
    top: -40px;
  }
`;
const AddRightInner = styled.div`
  width: 100%;
`;
const AddImgWrapp1 = styled.div`
  width: 48%;
  margin: 0 6% 10px 6%;

  img {
    width: 100%;
    height: auto;
    border-radius: 1rem;
    box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
  }
`;
const AddImgWrapp2 = styled.div`
  width: 30%;
  margin: 0 5% 10px 5%;

  img {
    width: 100%;
    height: auto;
    border-radius: 1rem;
    box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
  }
`;
const AddImgWrapp3 = styled.div`
  width: 20%;
  margin-left: 40%;

  img {
    width: 100%;
    height: auto;
    border-radius: 1rem;
    box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
  }
`;
const AddImgWrapp4 = styled.div`
  width: 30%;
  margin: 0 5% auto;

  img {
    width: 100%;
    height: auto;
    border-radius: 1rem;
    box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
  }
`;